export const OFF_THE_CLOCK_PATH = "/offtheclock";

const divisionByCourseIdQuery = `getDivisionByCourseId(courseId: "<<id>>")`;
const divisionIdByCourseIdQuery = `getDivisionIdByCourseId(courseId: "<<id>>")`;

export const checkUserImpersonationQuery = `query {checkUserImpersonation(userId: "<<userId>>", win: <<win>>, appName: "EDU"){permission}}`;
export const userRoleQuery =
  "query { getUser(win: <<win>>) { role, academy_id , division_name, division_id , ,first_name ,last_name } }";
//GraphQL Query - fetchUserProfile
export const fetchUserProfile = `query{
    fetchUserProfile(code:"<<code>>", redirecturi: "<<redirectURI>>", authorization: "<<authorization>>"){   
          accesstoken
          idtoken 
          userid
          storenum
          winnum
          clockin
          firstname
          lastname
          systemjobcode
          lawsonjobcode
          assocPayType
          }
  }`;

export const fetchUserProfileWithPingfed = `query{
    fetchUserProfileWithPingfed(clientid:"eduacademy", env:"<<env>>", authtype:"cert", code:"<<code>>", redirecturi: "<<redirecturi>>"){    
          userid
          storenum
          businessunitnum
          winnum
          accesstoken
          clockin
          firstname
          lastname
          systemjobcode
          lawsonjobcode
          assocPayType
          }
  }`;

export const GET_PAGES_COUNT = `
  query htmlPageCount {
    htmlPageCount(fileId: "<<fileId>>", version: <<version>> )
    htmlStylesByFileIdVersion(fileId: "<<fileId>>", version: <<version>>) {
      css
      id
    }
  }
`;

//GraphQL Query - fetchUserProfilewithuserid
export const fetchUserProfileWithUserid = `query{
    fetchUserProfileWithUserId (userid:"<<userid>>", storenum: "<<storenum>>"){    
          userid
          storenum
          winnum
          firstname
          lastname
          systemjobcode
          lawsonjobcode
          assocPayType
          }
  }`;

// GraphQL API to get the Roster course and the user division courses
export const fetchUserCoursesQuery = `query{
   me(win :<<win>>) {
    dashboardRosters {
      course_title
      start
      end
      deleted
      enrolled_users
      course_id
      roster_id
      academy_id
      modality
      wm_week
      is_favorite
      group {
        parent_name
        parent_id
      }
    }
    displayCourses {
      id
      name
      parent_id
      parent_name
      history {
        occurred
      }
      metadata {
        name
        description
        content_type
        createdOn
        access_win
        lock_access_win
        flagCrs
      }
      is_protected
      is_locked
      is_archive
      is_favorite
    }
  }
}`;

export const fetchDivisions = `query{
  getDivisions {
    id
    name
    contents{
      id
      name
      parent{
        id
        name
      }
    }
  }
}`;

//GraphQL Query - To get Course Details
export const courseDetails = `query courseDetails {
  foldersByParentId(parentId: "<<id>>") {
    name
    parent_name
    parent_id
    id
    history {
      occurred
    }
    metadata {
      name
      content_type
      createdOn
      aemContentLocation
      description
      survey_link
      ordering
      facilitator_guide
      participant_guide
      access_win
      lock_access_win
      order
      module_aem_guide_title
      module_aem_guide_link
    }
    files {
    alias
    file_id
    }
  }
  getFolder(id: "<<id>>") {
    id
    name
    metadata {
      description
      survey_link
      facilitator_guide
      participant_guide
      access_win
      lock_access_win
      module_aem_guide_title
      module_aem_guide_link
    }
    is_protected
    is_locked
    is_archive
    is_survey_enabled
    survey_id
    survey_link
  }
  validateAssessment(courseId: "<<id>>",courseRoster: "<<courseRoster>>") {
    can_take_post
    prompt_code
    can_take_pre
    no_pre_assessment
    no_post_assessment
    pre_error_message
    assessment_uploaded
    attendance_given
    roster_id
    start
    end
    schedule_id
    wm_week
    not_first_attempt
    enrolled_users
    enrolled_users_status
    post_error {
    message
    days_to_wait
    days_since_window_closed
    prev_attempts
    }
    assigned_course
    scoreArray
    preAssessmentId
    postAssessmentId
    engPostAssessemntID
    engPreAssessemntID
    spanishPostAssessemntID
    spanishPreAssessemntID
    spanishPostAssessemntPassPercent
    engPostAssessemntPassPercent
  }
  me(win :<<win>>) {
    win
    first_name
    last_name
    academy_id
    role
  }
}`;

//GraphQL Query - To get Roster details of faicilitator
export const facilitatorRoster = `query 
rostersByCourseId {
  rostersByCourseIdAcademyId(courseId: "<<courseId>>", academyId: <<academyId>>) {
    roster_id
    deleted
    created
    academy_id
    course_id
    course_title
    modality
    created_by
    deleted_by
    end
    enrolled_users
    facilitator
    qb_course_id
    qb_roster_id
    start
    wm_week
    ams_schedule_id
    ams_class_id
    ams_external
  }
}`;

export const GetSessionToken = `
query getSessionToken{
  getSessionToken(assessmentId: "<<assessmentId>>"){
      win,
      token_group_id,
      token_id,
      status,
      token_group
}}`;

export const FACILITATOR_ROSTER_COURSE = `
query getRosters {
	getRosters(rosterId: "<<rosterId>>") {
	roster_id
	deleted
  created
  wm_week
	academy_id
	course_id
	course_title
  modality
	created_by
	deleted_by
	end
  enrolled_users
  enrolled_users_status
	assignedFacilitators
  facilitator
  start
  division_name
	facilitator_details {
		first_name
		last_name
	}
	participant_performances {
		win
		name
		store_number
		pre_test_results
		post_test_results
		post_test_attempt_number
    missed_questions_count
    missed_questions_count_action
    missed_questions_topics
    first_name
    last_name
    email
    post_assessment_id
    post_assessment_name
    post_assessment_version
    post_result_pass
    post_assessment_pass_percent
    academy_id
    created
    division_id
    course_id
	}
  }
}`;

//GraphQL Query - To get user course login history details
export const fetchUserCoursesHistoryQuery = `query{
  getUserLoginHistory{
    course_id
    created
  }
}`;

//GraphQL Query - update user course login history details
export const UserCoursesHistoryMutation = `mutation{
  upsertUserLoginHistory(win: <<win>>, courseId: "<<courseId>>") {
    win
    created
    course_id
  }
}`;

export const getNotesQuery = "contentCommentByFileIdWin";
export const GET_NOTES_QUERY = `
query contentCommentByFileIdWin {
  contentCommentByFileId(fileId: "<<fileId>>") {
      comment_id
      file_id
      comment_body
      ordering
      deleted
      created
      updated
    }
  }
`;

export const htmlPages = "htmlPages";
export const GET_PAGES = `
  query {
    htmlPages(fileId: "<<fileId>>", version: <<version>>, pageNumber: <<pageNumber>>)
  }
`;
export const AEM_JSON = `
  query {
    aemJson(path: "<<path>>")
  }
`;
export const ADD_USER = ` mutation
{
  userCreate(userWin:<<win>>,role:<<role>>,academyId:<<academyId>>,divisionId:<<divisionId>>,division:<<division>>,
    firstName:<<firstName>>,lastName:<<lastName>>,email:<<email>>,userId:<<userId>>)
  {
    win
    division_id
    role
    academy_id
  }
}
 `;

export const MODIFY_USER = ` mutation
{
  userCreate(userWin:<<win>>,role:<<role>>, academyId:<<academyId>>,divisionId:<<divisionId>>,division:<<division>>) {
    win
    division_id
    role
    academy_id
  }
}
 `;
export const AD_USER = `
  query {
  adUser(win:<<win>>) {
   first_name
   last_name 
   email
   user_id
   win
  }
}
 `;
export const USER_QUERY = `
query{
  getUser(win:<<win>>){
    win
    first_name
    last_name
    role
    academy_id
    division_id
    modified_date
    division_name
    created
    store
    user_id
    dashboardRosters{
      roster_id
      academy_id
      enrolled_users
      wm_week
      course_title
      start
      created
      is_favorite
      modality
    }
    displayCourses {
      id
      name
      parent_id
      parent_name
      history {
        occurred
      }
      metadata {
        name
        description
        content_type
        createdOn
        access_win
        lock_access_win
        flagCrs
      }
      is_protected
      is_locked
      is_archive
      is_favorite
    }
  }
}
`;
export const DELETE_USER_QUERY = `
  mutation
{
  deleteUser(win:<<win>>)

}`;
// mark course as favorite
export const MARK_COURSE_FAVORITE = `
  mutation
{
  favoriteCourse(win:"<<win>>", courseId: "<<courseId>>", favorite: <<favorite>>) 
  {  
    course_title
    start
    end
    deleted
    enrolled_users
    course_id
    roster_id
    academy_id
    is_favorite
    wm_week
    group {
      parent_name
      parent_id
    }
  }
}`;
export const DIVISIONS_QUERY = `
query{
  getDivisions {
      id
      name
      is_protected
      is_locked
      metadata
      readFromBox
      updated
      path{
        total_count
        entries{
          id
          name
        }
      }
        contents{
          id
          is_protected
          is_locked
          metadata{
            description
            facilitator_guide
            participant_guide
            survey_link
          }
          name
          type
          updated
          parent{
            id
            name
        }
    }
  }
}
`;

export const MODULE_QUERY = `
query {
  foldersByParentId(parentId: "<<parentId>>") {
    name
    parent_name
    parent_id
    metadata {
      name
      content_type
      createdOn
      aemContentLocation
      description
      survey_link
      ordering
      facilitator_guide
      participant_guide
      access_win
      lock_access_win
      __typename
    }
    files {
      alias
      file_id
      released
      __typename
    }
    history{
      occurred
    }
    id
    __typename
  }
  me(win :<<win>>) {
    first_name
    last_name
    __typename
  }
  getFolder(id: "<<parentId>>") {
    id
    name
    parent_id
    deleted
    metadata {
      description
      survey_link
      facilitator_guide
      participant_guide
      access_win
      lock_access_win
    }
    is_protected
    is_locked
  }
}
`;

export const DOCUMENTS_QUERY = `
query {
  getFolder(id: "<<id>>") {
    name
    parent_name
    parent_id
    id
    metadata {
      name
      content_type
      createdOn
      aemContentLocation
      description
      survey_link
      ordering
      facilitator_guide
      participant_guide
      module_aem_guide_title
      module_aem_guide_link
      access_win
      lock_access_win
      __typename
    }
    files {
      alias
      file_id
      released
      deleted
      user_role
      version
      is_released    
      metadata { 
          name      
          content_type 
      }
      history{
          occurred
      } 
      __typename
    }
    linksReleased {
      created
      type
      content_link
      content_name
      content_id
      __typename
    }
    id
    __typename
  }
  me(win :<<win>>) {
    win
    first_name
    last_name
    __typename
  }
}`;

//query to fetch the assessment data by course id and type
export const ASSESSMENT_DATA = `query { assessmentByCourseIdType(courseId: "<<courseId>>", type: "<<type>>", language: "<<language>>", isAssessmentResume: <<isAssessmentResume>>)
  {
    assessment_id
    questions {
      question
      question_id
      topic
      assessment_id
      answers
      correct_answer
      invalidated
      invalidate_reason
      sequence_number
    }
    deleted
    created
    created_by
    deleted_by
  }
}`;

//query to check the assessment code
export const CHECK_ASSESSMENT_CODE = `query { 
  checkAssessmentCode(courseId: "<<courseId>>", assessmentCode: "<<assessmentCode>>", courseRoster: "<<courseRoster>>")
}`;

//query to submit the assessment (PRE, Take and Attendance)
export const SUBMIT_ASSESSMENT_MUTATION = `mutation {
    participantPerformanceSubmit(
      questions: <<questions>>
      courseId: "<<courseId>>"
      assessmentId: "<<assessmentId>>"
      academyId: <<academyId>>
      type: "<<type>>"
      courseRoster: "<<courseRoster>>"
      tokenId: "<<tokenId>>"
    ) {
      isTokenValid
      ParticipantPerformanceResult{
        percentage
        courseName
        missedQuestions
        totalQuestions
        pass
        missedQuestionTopics
        userWin
        surveyLink
        attemptNum
      }
    }
  }
`;

// Assessment answer validation call
export const ASSESSMENT_VALIDATION = `
  query {
    assessmentAnswers(assessmentId: "<<assessmentId>>") {
      win
      assessment_id
      question
      deleted
      answer
      created
      created_by
      deleted_by
      update_reason
      updated
      updated_by
    }
    assessmentAndQuestionValidation(assessmentId: "<<assessmentId>>", questionIds: "<<questionIds>>",tokenId:"<<tokenId>>",includeDeleted:<<includeDeleted>>){
      isAssmtAltered
      isQuestionsAltered
      errMsg
      isTokenValid
    }
  }
`;

//query to fetch the assessment answers
export const ASSESSMENT_ANSWERS = `query {
    assessmentAnswers(assessmentId: "<<assessmentId>>") {
        win
        assessment_id
        question
        deleted
        answer
        created
        created_by
        deleted_by
        update_reason
        updated
        updated_by
      }
    }
`;

//query to update the assessment answer
export const ASSESSMENT_MUTATION = `mutation {
    updateAssessmentAnswers(
      assessmentId: "<<assessmentId>>"
      question: "<<question>>"
      answer: "<<answer>>"
      updateReason: "<<updateReason>>"
      tokenId: "<<tokenId>>"
    ) {
      isTokenValid
      assessmentAnswerHistory{
        win
        assessment_id
        question
        deleted
        answer
        created
        created_by
        deleted_by
        update_reason
        updated
        updated_by
      }
    }
  }
`;

//query to fetch the divisions, groups and courses
export const GET_MANAGE_CONTENT_QUERY = `query {
  getDivisions {
    id
    name
    contents{
      id
      name
      parent{
        id
        name
      }
    }
  }
  getAllCourses{
    name
    id
    parent_id
    parent_name
    is_protected
    is_archive
    is_locked
    is_favorite
    history{
        occurred
    }
    metadata {
      access_win
      lock_access_win
      flagCrs
    }
  }
  getFolderGroup {
    folder_ids
  }
}
`;

export const COURSE_WIN_VALIDATION = `query {
  courseWinValidation(accessWin: "<<accessWin>>", courseType: <<courseType>>)
}
`;

export const ADMIN_COURSE_DETAILS = `query courseDetails {
  foldersByParentId(parentId: "<<id>>") {
    name
    parent_name
    parent_id
    id
    history {
      occurred
    }
    metadata {
      name
      content_type
      createdOn
      aemContentLocation
      description
      survey_link
      ordering
      facilitator_guide
      participant_guide
      access_win
      lock_access_win
      flagCrs
      order
    }
    files {
    alias
    file_id
    }
  }
  getFolder(id: "<<id>>") {
    id
    name
    parent_id
    deleted
    metadata {
      description
      survey_link
      facilitator_guide
      participant_guide
      access_win
      lock_access_win
      flagCrs
    }
    is_protected
    is_locked
    is_archive
    is_survey_enabled
    survey_id
    survey_link
  }
  contentAssessment(courseId:"<<id>>"){
    file_id,
    name,
    version,
    is_deleted,
    is_released,
    parent_folder_id
    question_count
    type
    status
    language
    user_role
    history{
        occurred
    }
  }
  validateAssessment(courseId: "<<id>>",courseRoster: "NA") {
    can_take_post
    prompt_code
    can_take_pre
    no_pre_assessment
    no_post_assessment
    pre_error_message
    assessment_uploaded
    attendance_given
    roster_id
    start
    end
    schedule_id
    wm_week
    not_first_attempt
    post_error {
    message
    days_to_wait
    days_since_window_closed
    prev_attempts
    }
    assigned_course,
    preAssessmentId
    postAssessmentId
    engPostAssessemntID
    engPreAssessemntID
    spanishPostAssessemntID
    spanishPreAssessemntID
    spanishPostAssessemntPassPercent
    engPostAssessemntPassPercent
  }
  me(win :<<win>>) {
    win
    first_name
    last_name
    academy_id
    role
  }
  ${divisionByCourseIdQuery}
}`;

export const GET_DIVISION_BY_COURSE_ID = `query {
  ${divisionByCourseIdQuery}
}`;

export const GET_DIVISION_ID_BY_COURSE_ID = `query {
  ${divisionIdByCourseIdQuery}
}`;

export const getActionPlanQuery = `{
  getActionPlan(divisionId : "<<divisionId>>",
   week : "<<week>>",academy: "<<academyId>>", divArr: "<<divArr>>")
   {
     selected,
     users    
   }
 }`;
export const getActionPlanByWinQuery = `{
  getActionPlanForWin(win:"<<winNbr>>")
 {
   name,
   users    
 }
}`;
export const getAcademies = `{
  getAcademies{
    academy_id,
    created,
    deleted,
    division_id
  }
  }`;
export const getDivisionsForActionPlan = `query {
  getDivisions {
    id
    name   
  }
}`;

export const GET_ALL_DIVISIONS_ACADEMIES = `
query {
  getAcademies {
    academy_id
    division_id
  },
  getDivisions {
    id
    name
  }
}`;

export const GET_ROSTERS_BY_ACADEMY_DIVISION = `
query {
  rostersByAcademyIdDivisionId(academyId: <<academyId>>, divisionId: "<<divisionId>>", divisionName: "<<divisionName>>") {
    roster_id
    created
    course_id
    course_title
    enrolled_users
    facilitator
    start
    wm_week
    modality
  }
}`;

//Pending releases queries

export const getQueueForDivision = `
{
  getQueue(divisionId : "<<divisionId>>") 
   
 }`;
const folderQuery = `
  getFolder(id: "<<id>>") {
    id
    name
    parent_id
    deleted
    parent_name
    metadata {
      description
      survey_link
      facilitator_guide
      participant_guide
      access_win
      lock_access_win
    }
    is_protected
    is_locked
  }`;

export const fetchCourseDetails = `query {
  ${folderQuery}
}`;

export const GET_FOLDER_MODULES = `query {
  foldersByParentId(parentId: "<<id>>") {
    id
    name
    parent_name
    parent_id
    history {
      occurred
    }
    metadata {
      survey_link
    }
  }
  ${folderQuery}
}`;

export const GET_MODULE_DETAILS = `query { 
  getFolder(id: "<<id>>") {     
      id  
      name    
      parent_name
      deleted    
      parent_id  
      files { 
          alias      
          file_id      
          user_role      
          version
          is_released    
          metadata { 
              name      
              content_type 
          }
          history{
              occurred
          }  
      }
      metadata { 
        name      
        content_type
        description
        survey_link
        ordering
        facilitator_guide
        participant_guide 
        module_aem_guide_title
        module_aem_guide_link
      }
      linksReleased {       
          content_id     
          content_name     
          created      
          type      
          content_link 
      }  
      linksPending{      
          content_id     
          content_name     
          created      
          type      
          content_link 
      }
  }
  parentFolder:getFolder(id: "<<parentId>>") {     
      id
      name    
      parent_name    
      parent_id
  }
}`;

export const GET_ASSESS = `query {  viewAssessment(courseId:"<<courseId>>", assessmentId:"<<assessmentId>>",type:"<<type>>", canEditAssessment: <<canEditAssessment>>){
    assessment_id,
    course_id,
    created,
    created_by,
    deleted,
    deleted_by,
    first_retake_window,
    language,
    modified_date,
    pass_percent,
    retake_delay,
    role,
    second_retake_window,
    total_attempts,
    type,
    active_question_count,
    division_name,
    questions{
      question_id,
      question,
      sequence_number,
      topic,
      invalidated,
      correct_answer
      answers{
        idx
        answer
        correct
        percentage
      }
    },
    canEditAssessment
  }
}`;

export const GET_FACILITATOR_BY_ACADEMY = `query {
  getUsers(academyId: <<academyId>>, role: facilitator) {
    win
    deleted
    academy_id
    created
    email
    first_name
    last_name
    role
    store
    user_id
    current_aem_page
    division_id
    rosters
  }
}`;

export const GET_HIGHLIGHTED_TEXT = `
  query {
    highlights(fileId: "<<fileId>>", page_num: "<<pageNum>>") {
      quote
      ranges {
        start
        startOffset
        end
        endOffset
      }
      page_num
      id
      color_code
    }
  }
`;
//fetch invalidated participant records
export const FETCH_INVALIDATED_RECORDS = `
query {
    fetchInvalidatedParticipantRecords(assessmentId:"<<assessmentId>>",
    questionId:"<<questionId>>"){
        assessment_id
        academy_id
        attempt_num
            new_score
            previous_score
        user{
            academy_id
            box_id
            first_name
            last_name
            win
            academy_id
        }
    }
}`;

// Quey to validate local copy of assmt token agaist BD
export const VALIDATE_ASSMT_TOKEN = `
query {
  sessionTokenValidation(win: <<win>>, assessmentId:"<<assessmentId>>",  tokenId:"<<tokenId>>")
}`;

// Query to get Assessment validaton details base on courseId and courseRoster
export const VALIDATE_ASSESSMENT = `
query { 
  validateAssessment(courseId: "<<courseId>>",courseRoster: "<<courseRoster>>") {
    can_take_post
    can_take_pre
    pre_error_message
    roster_id
    start
    end
    wm_week
    not_first_attempt
    assigned_course
    preAssessmentId
    postAssessmentId
    post_error {
      message
      days_to_wait
      days_since_window_closed
      prev_attempts
    }
    engPostAssessemntID
    engPreAssessemntID
    spanishPostAssessemntID
    spanishPreAssessemntID
    spanishPostAssessemntPassPercent
    engPostAssessemntPassPercent
  }
}`;

export const ASSESSMENT_MUTATION_WITH_VAR = `mutation 
asmtAnsUpdate(
  $assessmentId: String!
  $question: String!
  $answer: String!
  $updateReason: String!
  $tokenId: String!
 
) {
  updateAssessmentAnswers(
    assessmentId:  $assessmentId
    question: $question
    answer:  $answer
    updateReason: $updateReason
    tokenId: $tokenId
  ) {
    isTokenValid
    assessmentAnswerHistory{
      win
      assessment_id
      question
      deleted
      answer
      created
      created_by
      deleted_by
      update_reason
      updated
      updated_by
    }
  }
}
`;
export const ASSESSMENT_DOWNLOAD = `
query assessmentDownload(
  $courseId: String!
  $courseName: String!
  $assessmentId: String!
  $type: String!
  $withAnswerKeys: Boolean
  $withOptionPercentage: Boolean
) {
  downloadAssessmentDoc(
    courseId: $courseId,
    courseName: $courseName,
    assessmentId: $assessmentId,
    type: $type,
    withAnswerKeys: $withAnswerKeys,
    withOptionPercentage: $withOptionPercentage
  )
}
`;

export const UPDATE_COURSE_PROGRESS_MUTATION = `mutation{  updateCourseProgress(payload: {winNum: "<<winNum>>", 
courseId: "<<courseId>>", source: "<<source>>", courseStatus: "<<courseStatus>>", 
startdate: "<<startdate>>", duedate: "<<duedate>>"})}`;
