import * as _ from 'lodash';
import queryString from 'query-string';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {localStorageConstants} from '../../constants/LocalStorageConstants';
import LoadingPage from '../../pages/initialloading';
import {getParameterByName, getUserDefaultUrl, SSOlogin} from '../../utils/common';
import {clear, getItem, removeItem, setItem} from '../../utils/storage';
import {commonConstants} from "../../constants/CommonConstants";
import {externalToken} from '../../dao/token';
import Authentication from '../authentication';
import Authorization from '../authorization';
import {auth} from '../../dao/user/externaluser'
import {initializeGrpahQLDB} from '../../cache/indexeddb';
import qs from 'query-string';
import { ToastContainer, toast } from 'react-toastify';


import {
  getUserLogin,
    getRequestedPath,
    getLoginTime,

} from '../../utils/userprofile';

import {failOverCheck} from '../failover';
import { eraseCookie, getCookie } from '../../utils/cookies';

function loadingPage() {
    return <LoadingPage></LoadingPage>;
}

const authentication = new Authentication;
const authorization = new Authorization;

export function securityFilter(Component) {
    return class SecurityFilter extends React.Component {
        constructor(props) {
            super(props);
            initializeGrpahQLDB();
            this.state = {
                loading: true,
                role: false,
                permitted: false
            };

        }


         async componentDidMount() {
            const redirecturl = await this.checkExternalRedirect();
            if(redirecturl === ''){
              const requestedPath = window.location.pathname + window.location.search;
              const queryPrams = qs.parse(window.location.search);
              if (commonConstants.ANONYMOUS_PAGES.indexOf(requestedPath) !== -1 || queryPrams.redirecturl || queryPrams.email || requestedPath.indexOf('/activateaccount') != -1) {
                  removeItem(localStorageConstants.REQUESTED_PATH);
                  if (requestedPath.indexOf("localLogout") !== -1) {

                      window.location.href = "/logout";
                  }
                  this.setState({permitted: true, localloading: true});
              } else {

                  if (requestedPath.indexOf("impersonateuser") != -1 && window.ENV.REACT_APP_IMPERSONATION_CHECK === "false") {
                      this.setState({permitted: true, localloading: true});
                  } else {
                      if (requestedPath.indexOf("/healthandwellness") === -1) {
                          this.precheckLogin();
                      }
                  }
              }
            } else {
                window.location.href = redirecturl;
            }
            
        }

        /**
     * Authencate and Login and get user profile
     */
        precheckLogin = async () => {
           await failOverCheck();
            const qsKeys = queryString.parse(window.location.search);
            const authCode = qsKeys.code;
            const isDoubleLoginEnabled = window.ENV.REACT_APP_DOUBLE_LOGIN_FLAG === 'true'
            setItem(localStorageConstants.AUTH_CODE, authCode);
            await authorization.setAemDisplay();
            if (!isDoubleLoginEnabled && getParameterByName('userid', window.location.search) && getParameterByName('storenum', window.location.search)) {
                
                const params = {
                    userid: getParameterByName('userid', window.location.search).toLowerCase(),
                    storenum: getParameterByName('storenum', window.location.search)
                };
                await authentication.getUserProfile("userid", params);
                await authorization.checkPilotAcademy();
                await this.checkPermission();
                
            } else if (getParameterByName('accountname', window.location.search) && getParameterByName('businessunitnumber', window.location.search)) {
                
                const source = getParameterByName('source', window.location.search);
                source && setItem(localStorageConstants.SOURCE, source);
                if (isDoubleLoginEnabled) {
                    toast("SSO login triggered")
                    return SSOlogin()
                } else {
                    const accountName = getParameterByName('accountname', window.location.search).toLowerCase();
                    const token = getParameterByName('token', window.location.search);
                    if (token) {
                        const tokenResponse = await externalToken(token);
                        if (!tokenResponse.data.responseData || tokenResponse.data.responseData.active !== 'true') {
                            window.location.href = commonConstants.UNAUTHORIZED_PATH;
                            return;
                        }
                    }
                    let userid = '';
                    let storenum = '';
                    if (accountName.indexOf('.') > 0) {
                        userid = accountName.split('.')[0];
                        storenum = accountName.split('.')[1].substr(1, accountName.split('.')[1].length);
                    } else {
                        userid = accountName;
                        storenum = getParameterByName('businessunitnumber', window.location.search);
                    }
                    const params = {
                        userid: userid,
                        storenum: storenum
                    };

                    await authentication.getUserProfile("userid", params);
                    const user = (getItem(localStorageConstants.IS_VALID_USER))
                    if (user === 'valid') {
                        const requestedPath = getUserDefaultUrl();
                        setItem(localStorageConstants.REQUESTED_PATH, requestedPath);
                        window.location.href = requestedPath;
                        await this.checkPermission();
                    } else {
                        window.location.href = commonConstants.USERNOTFOUND;
                    }
                }

            } else if (getParameterByName('accountname', window.location.search)) {
                
                if (isDoubleLoginEnabled) {
                    toast("SSO login triggered")
                    return SSOlogin()
                } else {
                    const accountName = getParameterByName('accountname', window.location.search).toLowerCase();
                    const token = getParameterByName('token', window.location.search);
                    if (token) {
                        const tokenResponse = await externalToken(token);
                        if (!tokenResponse.data.responseData || tokenResponse.data.responseData.active !== 'true') {
                            window.location.href = commonConstants.UNAUTHORIZED_PATH;
                            return;
                        }
                    }
                    let userid = '';
                    let storenum = '';
                    if (accountName.indexOf('.') > 0) {
                        userid = accountName.split('.')[0];
                        storenum = accountName.split('.')[1].substr(1, accountName.split('.')[1].length);
                    } else {
                        userid = accountName;
                        storenum = '';
                    }
                    const params = {
                        userid: userid,
                        storenum: storenum
                    };

                    await authentication.getUserProfile("userid", params);
                    const user = (getItem(localStorageConstants.IS_VALID_USER))
                    if (user === 'valid') {
                        const requestedPath = getUserDefaultUrl();
                        setItem(localStorageConstants.REQUESTED_PATH, requestedPath);
                        window.location.href = requestedPath;
                        await this.checkPermission();
                    } else {
                        window.location.href = commonConstants.USERNOTFOUND;
                    }
                }
                
            } else if (!getUserLogin()) { /* This block executed before redirecting to IAM login screen */
                if(getItem(localStorageConstants.EXTERNAL_URL_REDIRECT) === 'true'){
                clear();
                window.localStorage.setItem(localStorageConstants.EXTERNAL_URL_REDIRECT,'true');
                } else{
                clear();
                }
                let requestedPath = window.location.pathname + window.location.search;
                requestedPath = requestedPath === '/?localLogout=true' ? '/logout' : requestedPath;
                setItem(localStorageConstants.REQUESTED_PATH, requestedPath);
                authentication.login(requestedPath.includes("code") ? false : true);
            } else if (!getItem(localStorageConstants.USER_PROFILE_PRESENT)) {
                if (authCode && authCode !== '') {

                    setItem(localStorageConstants.AUTH_CODE, authCode);

                    const params = {
                        code: authCode,
                        redirecturi: window.ENV.REACT_APP_IAM_REDIRECT_URI,
                        authorization: window.ENV.REACT_APP_IAM_AUTHORIZATION
                    };

                    await authentication.getUserProfile("code", params);
                    const user = (getItem(localStorageConstants.IS_VALID_USER))
                    if (user === 'valid') {
                        const requestedPath = getUserDefaultUrl();
                        setItem(localStorageConstants.REQUESTED_PATH, requestedPath);
                        window.location.href = requestedPath;
                        await this.checkPermission();

                    } else {
                        window.location.href = commonConstants.USERNOTFOUND;
                    }
                } else {
                    clear();
                    authentication.login(true);
                } 
            } else {
                const redirecturl = await this.checkExternalRedirect();
                if(redirecturl === ''){
                  await removeItem(localStorageConstants.REQUESTED_PATH);
                  await this.checkPermission();
                } else {
                  window.location.href = redirecturl;
                }
            }
            
        };

        checkExternalRedirect = async () => {
            let encodedUri = '';
            if(getCookie('externalurlredirect') && getItem(localStorageConstants.WIN)){
                const redirecturl = getCookie('redirecturl')
                const win = btoa(getItem(localStorageConstants.WIN));
                const redirectTargetMoodle = (redirecturl && redirecturl.toLowerCase().search('supplier') !== -1) ? true : false
                const userID = getItem(localStorageConstants.USER_ID) ? btoa(getItem(localStorageConstants.USER_ID)) : '';
                clear()
                eraseCookie('externalurlredirect')
                eraseCookie('redirecturl')
                encodedUri = redirectTargetMoodle ? `${redirecturl}?param0=${userID}` : `${redirecturl}?param0=${win}`
                if(redirecturl) {
                  const geenratedTokenResponse = await auth({
                    "expiresin": "60s"
                  }, 'GenerateToken')
                  if (geenratedTokenResponse && geenratedTokenResponse.token) {
                    encodedUri = `${encodedUri}&token=${geenratedTokenResponse.token}`
                  }
                }
                return encodedUri;
            } else{
                return encodedUri;
            }
        }

        /*
     * Check user permission based on user role
     */
        checkPermission = async () => {
            const pathName = getRequestedPath() || window.location.pathname;

            if ((await authorization.checkPermission(pathName)) === true) {
                this.setState({permitted: true});
                this.checkLoading(false, true);

                removeItem(localStorageConstants.REQUESTED_PATH);
            } else {
                removeItem(localStorageConstants.REQUESTED_PATH);
                window.location.href = commonConstants.UNAUTHORIZED_PATH;
            }
        };
        /**
     * checks to stop loading symbol
     */
        checkLoading = (loading) => {
            if (this.state.loading) {
                this.setState({loading: true});
            }
        };
        /**
     * Render
     */
        render() {

            return (
                <> {
                    this.state.permitted ? (
                        <> {
                            this.state.loading ? (
                                <React.Fragment>
                                    <Component/>
                                </React.Fragment>
                            ) : (loadingPage())
                        } </>
                    ) : (loadingPage())
                } <ToastContainer /></>
            );
        }
    };
}

export default withRouter(securityFilter);