import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { localStorageConstants } from "../../../constants/LocalStorageConstants";
import {
  getAssessmentDataByCourseIdType,
  resetPrevAssessment,
  upsertAssmtToken,
} from "../../../dao/assessment";
import { makeStyles, Menu, MenuItem } from "../../../lib/materialui";
import "../../../pages/facilitator/pdfpage/scss/fileDisplay.scss";
import {
  trackPreAssessmentButtonClickEvent,
  trackTakeAssessmentButtonClickEvent,
  trackResumeAssessmentButtonClickEvent,
  trackPauseAssessmentButtonClickEvent,
} from "../../../utils/userTelemetry/index";
import { getItem, setItem } from "../../../utils/storage";
import AssessmentModal from "../../assessment/assessment";
import ValidationDialog from "../../assessment/validationdialog";
import Button from "../../common/button";
import SnackbarComponent from "../../assessment/assessment/snackbar";
import { commonConstants } from "../../../constants/CommonConstants";
import {
  getCourseDetails,
  upsertUserCrsHistory,
  getSessionToken,
} from "../../../dao/course";
import { useParams, useLocation } from "react-router-dom";
import styles from "./styles";
import ResumeAssmtDialogBox from "../../common/resumeAssessmentDialog";
import { genAssmtSessionToken, saveSurveyDetails } from "../../../utils/common";

const AssessmentControls = (props) => {
  const useStyles = makeStyles((theme) => styles);
  const classes = useStyles();
  const {
    assessmentControlsContainer,
    assessmentControlsButtonContainer,
    takePreAssessmentBtn,
    takeAssessmentBtn,
  } = classes;

  const [assessmentData, setAssessmentData] = useState();
  const [openValidation, setOpenValidation] = useState(false);
  const [openAssessment, setOpenAssessment] = useState(false);
  const [type, setType] = useState("false");
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [isPaused, setIsPaused] = useState(false);
  const [isPrePaused, setIsPrePaused] = useState(false);
  const [code, setCode] = useState();
  const [isPreStarted, setIsPreStarted] = useState(false);
  const [isPostStarted, setIsPostStarted] = useState(false);
  const demoMode = getItem(localStorageConstants.DEMO_MODE);
  const [manageContentTab, setManageContentTab] = useState("");

  const [course, setCourse] = useState();
  const [loading, setLoading] = useState(true);
  const [scoreArr, setScoreArr] = useState([]);
  const { id } = useParams();
  const [courseId, setCourseId] = useState(id ? id : getItem("CourseId"));
  const dashboardRoster = getItem(localStorageConstants.COURSE_ROSTER_ID);
  const courseRoster = dashboardRoster ? dashboardRoster : "NA";
  const location = useLocation();

  const [isSurveyEnabled, setIsSurveyEnabled] = useState(false);
  const [surveyLink, setSurveyLink] = useState("");
  const [isSurveyClicked, setIsSurveyClicked] = useState(false);
  const [canAssessmentEnabled, setCanAssessmentEnabled] = useState(true);
  const [timer, setTimer] = useState(0);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  const [isMultiLanguagePost, setIsMultiLanguagePost] = useState(false);
  const [isMultiLanguagePre, setIsMultiLanguagePre] = useState(false);
  const [preResumeDetails, setPreResumeDetails] = useState({});
  const [postResumeDetails, setPostResumeDetails] = useState({});
  const [postAssessResume, setPostAssessResume] = useState(false);
  const [preAssessResume, setPreAssessResume] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPre, setAnchorElPre] = useState(null);
  const [selectedLanguagePre, setSelectedLanguagePre] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(false);
  const [openResumeDialog, setOpenResumeDialog] = useState(false);
  const [openResumeDialogPre, setOpenResumeDialogPre] = useState(false);

  useEffect(() => {
    if (isSurveyClicked) {
      if (timer > 0) {
        setTimeout(timeOutCallback, 1000);
      } else {
        setCanAssessmentEnabled(true);
      }
    }
  }, [timer, timeOutCallback]);
  const prepareAssessementPerLanguage = async (coursedata) => {
    const engPreAssessemntID = _.get(
      coursedata,
      "validateAssessment.engPreAssessemntID"
    );
    const spanishPreAssessemntID = _.get(
      coursedata,
      "validateAssessment.spanishPreAssessemntID"
    );
    const isPreAssmtExist = !!engPreAssessemntID || !!spanishPreAssessemntID;
    setIsMultiLanguagePre(engPreAssessemntID && spanishPreAssessemntID);

    const engPostAssessemntID = _.get(
      coursedata,
      "validateAssessment.engPostAssessemntID"
    );
    const spanishPostAssessemntID = _.get(
      coursedata,
      "validateAssessment.spanishPostAssessemntID"
    );
    const isPostAssmtExist = !!engPostAssessemntID || !!spanishPostAssessemntID;
    setIsMultiLanguagePost(engPostAssessemntID && spanishPostAssessemntID);

    let resumePost = false;
    let resumePre = false;
    if (engPostAssessemntID) {
      var engPostAssessemntData = await getSessionToken(engPostAssessemntID);
    }
    if (spanishPostAssessemntID) {
      var spanishPostAssessemntData = await getSessionToken(
        spanishPostAssessemntID
      );
    }
    if (
      (engPostAssessemntData && engPostAssessemntData.length > 0) ||
      (spanishPostAssessemntData && spanishPostAssessemntData.length > 0)
    ) {
      resumePost = true;
      setPostResumeDetails(
        engPostAssessemntData.length > 0
          ? { id: engPostAssessemntID, language: "english" }
          : { id: spanishPostAssessemntID, language: "spanish" }
      );
      setSelectedLanguage(
        engPostAssessemntData.length > 0 ? "english" : "spanish"
      );
      setPostAssessResume(true);
      setError(`Please review answers prior to submitting the assessment.`);
    }
    if (isPreAssmtExist) {
      if (engPreAssessemntID) {
        var engPreAssessemntData = await getSessionToken(engPreAssessemntID);
      }
      if (spanishPreAssessemntID) {
        var spanishPreAssessemntData = await getSessionToken(
          spanishPreAssessemntID
        );
      }

      if (
        (engPreAssessemntData && engPreAssessemntData.length > 0) ||
        (spanishPreAssessemntData && spanishPreAssessemntData.length > 0)
      ) {
        if (!resumePost || !isPostAssmtExist) {
          resumePre = true;
          setPreResumeDetails(
            engPreAssessemntData.length > 0
              ? { id: engPreAssessemntID, language: "english" }
              : { id: spanishPreAssessemntID, language: "spanish" }
          );
          setSelectedLanguagePre(
            engPreAssessemntData.length > 0 ? "english" : "spanish"
          );
          setPreAssessResume(true);
        }
      }
    }

    if (!(engPostAssessemntID && spanishPostAssessemntID) && !resumePost) {
      if (coursedata?.validateAssessment?.spanishPostAssessemntID) {
        setSelectedLanguage(commonConstants.ASSESSMENT_LANGUAGE_SPANISH);
      } else {
        setSelectedLanguage(commonConstants.ASSESSMENT_LANGUAGE_ENGLISH);
      }
    }
    if (!(engPreAssessemntID && spanishPreAssessemntID) && !resumePre) {
      if (coursedata?.validateAssessment?.spanishPreAssessemntID) {
        setSelectedLanguagePre(commonConstants.ASSESSMENT_LANGUAGE_SPANISH);
      } else {
        setSelectedLanguagePre(commonConstants.ASSESSMENT_LANGUAGE_ENGLISH);
      }
    }
  };
  const getCourse = async () => {
    const coursedata = await getCourseDetails(courseId, courseRoster);
    if (!coursedata) {
      console.log("Error occured in getting course");
      setError(true);
      setErrMsg(
        "Error in fetching course data. Contact your Academy Coordinator if there is any issue or concern."
      );
      setLoading(false);
      return;
    }
    await prepareAssessementPerLanguage(coursedata);
    setCourse(coursedata);

    const va = coursedata.validateAssessment;
    if (va && va.scoreArray) {
      try {
        setScoreArr(JSON.parse(va.scoreArray));
      } catch (err) {
        // NOt a valid JSON string, setting it to default
        setScoreArr([]);
      }
    }

    const name = coursedata.getFolder[0] && coursedata.getFolder[0].name;
    const computedCourseId =
      coursedata.getFolder[0] && coursedata.getFolder[0].id;

    setItem("CourseId", computedCourseId);
    setItem("CourseName", name);

    setCourseId(computedCourseId);
    upsertUserCrsHistory(computedCourseId);

    if (va.roster_id && va.schedule_id) {
      localStorage.setItem(localStorageConstants.ROSTER_ID, va.roster_id);
      localStorage.setItem("scheduleId", va.schedule_id);
      localStorage.setItem("pageType", "NA");
    }
    if (va.wm_week && props.parentCallBack) {
      localStorage.setItem(localStorageConstants.WM_WEEK, va.wm_week);
      props.parentCallBack(va.wm_week);
    }
    const isSurveyEnabled = coursedata?.getFolder[0]?.is_survey_enabled;
    if (
      coursedata?.me?.role === commonConstants.PARTICIPANT &&
      isSurveyEnabled
    ) {
      setIsSurveyEnabled(isSurveyEnabled);
      setSurveyLink(coursedata?.getFolder[0]?.survey_link);
      let isCourseSurveyClicked = getItem("surveyList");
      if (isCourseSurveyClicked) {
        isCourseSurveyClicked = JSON.parse(isCourseSurveyClicked);
        if (
          isSurveyEnabled &&
          isCourseSurveyClicked.indexOf(computedCourseId) === -1
        ) {
          setCanAssessmentEnabled(false);
        }
      } else {
        setCanAssessmentEnabled(false);
        setIsSurveyClicked(false);
      }
    }

    setLoading(false);
  };

  const handleAssessmentSubmit = () => {
    setLoading(true);
    getCourse();
  };

  useEffect(() => {
    setLoading(true);
    getCourse();
  }, []);

  useEffect(() => {
    const tabName = location.pathname;
    setManageContentTab(tabName.slice(0, 27));
  }, [manageContentTab]);

  const isPreAssessmentEnabled = () => {
    const va = course.validateAssessment;
    if (demoMode) {
      return false; // no actions allowed
    } else if (
      !va.assessment_uploaded &&
      va.no_post_assessment &&
      va.no_pre_assessment
    ) {
      return false; // no assessment available
    } else if (
      course.me.role === commonConstants.PARTICIPANT &&
      (va.can_take_post || (va.can_take_pre && va.no_post_assessment)) &&
      va.can_take_pre &&
      !va.not_first_attempt
    ) {
      return true; // role is participant and pre assessment is available and post is not taken
    } else if (
      course.me.role &&
      course.me.role !== commonConstants.PARTICIPANT &&
      !va.no_pre_assessment &&
      va.can_take_pre
    ) {
      return true; // role is facilitator or admin, and pre assessment is available
    }
    return false;
  };

  const isPostAssessmentEnabled = () => {
    const va = course.validateAssessment;
    if (demoMode) {
      // no actions allowed
      return false;
    } else if (
      !va.assessment_uploaded &&
      va.no_post_assessment &&
      va.no_pre_assessment
    ) {
      // no assessment available
      return false;
    } else if (
      course.me.role === commonConstants.PARTICIPANT &&
      va.can_take_post
    ) {
      // role is participant and post assessment is available
      return true;
    } else if (
      course.me.role &&
      course.me.role !== commonConstants.PARTICIPANT &&
      !va.no_post_assessment
    ) {
      // role is facilitator or admin, and pre assessment is available
      return true;
    }
    return false;
  };

  const getAssessmentData = async (
    assessmentType,
    language = selectedLanguage,
    isAssessmentResume = false
  ) => {
    const va = course.validateAssessment;
    const assessmentDataFetched = await getAssessmentDataByCourseIdType(
      courseId,
      assessmentType,
      language,
      isAssessmentResume
    );
    if (assessmentDataFetched) {
      if (assessmentType === "PRE") {
        trackPreAssessmentButtonClickEvent(
          va.roster_id,
          assessmentDataFetched.assessment_id,
          courseId
        );
      }
      if (assessmentType === "POST" && !postAssessResume && !isPaused) {
        trackTakeAssessmentButtonClickEvent(
          va.roster_id,
          assessmentDataFetched.assessment_id,
          courseId
        );
        setOpenValidation(false);
      }
      if (assessmentType === "POST" && (postAssessResume || isPaused)) {
        setOpenValidation(false);
        trackResumeAssessmentButtonClickEvent(
          va.roster_id,
          assessmentDataFetched.assessment_id,
          courseId
        );
      }
      setIsPreStarted(false);
      setIsPostStarted(false);
      setAssessmentData(assessmentDataFetched);
    } else {
      console.log("Empty assessment data");
      setError(true);
      setErrMsg(
        "Assessment is no longer available. Contact your Academy Coordinator if there is any issue or concern."
      );
    }
  };
  const handleResumeDialogPre = (userOption) => {
    if (userOption === "resume") {
      setOpenResumeDialogPre(false);
      getAssessmentData("PRE", selectedLanguagePre, true);
      setOpenAssessment(true);
    } else if (userOption === "switch") {
      handleSwitchLanguagePre();
    } else {
      setOpenResumeDialogPre(false);
    }
  };
  const handleSwitchLanguagePre = async () => {
    const language =
      selectedLanguagePre.toLowerCase() ==
      commonConstants.ASSESSMENT_LANGUAGE_ENGLISH.toLowerCase()
        ? commonConstants.ASSESSMENT_LANGUAGE_SPANISH.toLowerCase()
        : commonConstants.ASSESSMENT_LANGUAGE_ENGLISH.toLowerCase();
    setSelectedLanguagePre(language);
    const result = await resetPrevAssessment(preResumeDetails.id);
    if (result.status) {
      setItem(localStorageConstants.COUNTER_IN_PROGRESS, 0);
      setOpenResumeDialogPre(false);
      getAssessmentData("PRE", language);
      setOpenAssessment(true);
    } else {
      setErrMsg(result.error);
    }
  };
  const createSession = async (assessmentId) => {
    const assmtToken = await genAssmtSessionToken();
    const isSessionCreated = await upsertAssmtToken(assessmentId, assmtToken);
    return isSessionCreated;
  };
  const handleSwitchLanguage = async () => {
    const language =
      selectedLanguage.toLowerCase() ==
      commonConstants.ASSESSMENT_LANGUAGE_ENGLISH.toLowerCase()
        ? commonConstants.ASSESSMENT_LANGUAGE_SPANISH.toLowerCase()
        : commonConstants.ASSESSMENT_LANGUAGE_ENGLISH.toLowerCase();
    setSelectedLanguage(language);
    const result = await resetPrevAssessment(postResumeDetails.id);
    if (result.status) {
      setItem(localStorageConstants.COUNTER_IN_PROGRESS, 0);
      const assessmentID =
        language == commonConstants.ASSESSMENT_LANGUAGE_ENGLISH.toLowerCase()
          ? course?.validateAssessment?.engPostAssessemntID
          : course?.validateAssessment?.spanishPostAssessemntID;
      const response = await createSession(assessmentID);
      if (!response) {
        return setErrMsg(
          "Not able to create a session at the time, Kindly try after sometime"
        );
      }
      setOpenResumeDialog(false);
      getAssessmentData("POST", language);
      setOpenAssessment(true);
    } else {
      setErrMsg(result.error);
    }
  };
  const handleClickPre = (language) => {
    setIsPreStarted(true);
    setType("PRE");
    setSelectedLanguagePre(language);
    getAssessmentData("PRE", language);
    setOpenAssessment(true);
  };

  const handleSurveyClick = () => {
    saveSurveyDetails(courseId)
    setIsSurveyClicked(true);
    setTimer(60);
    window.open(surveyLink, "_blank");
  };

  const handleClickTake = (language) => {
    setIsPostStarted(true);
    setType("POST");
    setSelectedLanguage(language);
    setOpenValidation(true);
  };

  const getPassPercent = () => {
    switch (selectedLanguage) {
      case commonConstants.ASSESSMENT_LANGUAGE_ENGLISH:
        return course.validateAssessment?.engPostAssessemntPassPercent;
      case commonConstants.ASSESSMENT_LANGUAGE_SPANISH:
        return course.validateAssessment?.spanishPostAssessemntPassPercent;
      default:
        return null;
    }
  };

  const handleResumeDialog = (userOption) => {
    if (userOption === "resume") {
      setOpenResumeDialog(false);
      getAssessmentData("POST", selectedLanguage, true);
      setOpenAssessment(true);
    } else if (userOption === "switch") {
      handleSwitchLanguage();
    } else {
      setOpenResumeDialog(false);
    }
  };

  const handleCloseValidation = () => {
    setIsPostStarted(false);
    setOpenValidation(false);
  };

  const getCode = (assessmentCode) => setCode(assessmentCode);

  const handleCloseAssessment = () => {
    const va = course.validateAssessment;
    trackPauseAssessmentButtonClickEvent(
      va.roster_id,
      assessmentData.assessment_id,
      courseId
    );
    setOpenAssessment(false);
    setAssessmentData();
    if (type === "POST") {
      setIsPaused(true);
      setPostResumeDetails({
        id: assessmentData.assessment_id,
        language: selectedLanguage,
      });
    }
    if (type === "PRE") {
      setIsPrePaused(true);
      setPreResumeDetails({
        id: assessmentData.assessment_id,
        language: selectedLanguagePre,
      });
    }
  };

  const handleConfirmation = () => {
    if (type === "POST") {
      getAssessmentData("POST");
      setOpenAssessment(true);
    }
  };
  const handleCloseLanguageSelectionMenu = () => {
    setAnchorEl(false);
  };
  const handleCloseLanguageSelectionMenuPre = () => {
    setAnchorElPre(false);
  };
  const handleAssessmentSubmission = () => {
    setIsPaused(false);
    setPostAssessResume(false);
    setIsPrePaused(false);
    setPreAssessResume(false);
    setOpenAssessment(false);
    handleAssessmentSubmit();
  };

  const getPreAssessmentButtonText = () =>
    preAssessResume || isPrePaused ? "Resume Pre-Test" : "Pre-Test";

  const getPostAssessmentButtonText = () =>
    postAssessResume || isPaused ? "Resume Assessment" : "Take Assessment";

  const getPreAssessmentButtonType = () =>
    postAssessResume || isPaused || isPostStarted ? "disabled" : "primary";

  const getPostAssessmentButtonType = () =>
    !canAssessmentEnabled || preAssessResume || isPrePaused || isPreStarted
      ? "disabled"
      : "primary";

  const showDialogs = () => {
    const va = course.validateAssessment;
    return (
      va &&
      va.assessment_uploaded && (
      !va.no_post_assessment ||
      !va.no_pre_assessment )
    );
  };
  const handleClickLanguageSelectionPre = (event) => {
    setIsPreStarted(true);
    setType("PRE");
    if (preAssessResume || isPrePaused) {
      if (isMultiLanguagePre) {
        setOpenResumeDialogPre(true);
      } else {
        getAssessmentData("PRE", selectedLanguagePre, true);
        setOpenAssessment(true);
      }
    } else {
      if (isMultiLanguagePre) {
        setAnchorElPre(event.currentTarget);
      } else {
        getAssessmentData("PRE", selectedLanguagePre);
        setOpenAssessment(true);
      }
    }
  };
  const handleClickLanguageSelection = (event) => {
    setIsPostStarted(true);
    setType("POST");
    if (postAssessResume || isPaused) {
      if (isMultiLanguagePost) {
        setOpenResumeDialog(true);
      } else {
        getAssessmentData("POST", selectedLanguage, true);
        setOpenAssessment(true);
      }
    } else {
      if (isMultiLanguagePost) {
        setAnchorEl(event.currentTarget);
      } else {
        setOpenValidation(true);
      }
    }
  };
  return (
    <>
      {manageContentTab !== "/admin/managecontent/course" &&
        !demoMode &&
        !loading && (
          <div
            id="assessment-controls-container"
            className={assessmentControlsContainer}
          >
            <div
              id="assessment-controls-buttons-container"
              className={assessmentControlsButtonContainer}
            >
              {isPreAssessmentEnabled() && (
                <Button
                  id="assessment-controls-button-take-pre"
                  buttonText={getPreAssessmentButtonText()}
                  buttonType={getPreAssessmentButtonType()}
                  buttonStyle={"pill"}
                  buttonSize={"small"}
                  openEvent={handleClickLanguageSelectionPre}
                  className={takePreAssessmentBtn}
                />
              )}
              {!postAssessResume &&
                isSurveyEnabled &&
                course?.me?.role === commonConstants.PARTICIPANT && (
                  <Button
                    id="assessment-controls-button-take-survey"
                    buttonText={"Take Survey"}
                    buttonType={"primary"}
                    buttonStyle={"pill"}
                    buttonSize={"small"}
                    openEvent={handleSurveyClick}
                    className={takeAssessmentBtn}
                  />
                )}
              {isPostAssessmentEnabled() && (
                <Button
                  id="assessment-controls-button-take-post"
                  buttonText={getPostAssessmentButtonText()}
                  buttonType={getPostAssessmentButtonType()}
                  buttonStyle={"pill"}
                  buttonSize={"small"}
                  openEvent={handleClickLanguageSelection}
                  className={takeAssessmentBtn}
                />
              )}
            </div>
            {showDialogs() && openValidation && (
              <ValidationDialog
                names={course.me}
                validateAssmt={course.validateAssessment}
                open={openValidation}
                handleClose={handleCloseValidation}
                handleConfirmation={handleConfirmation}
                courseId={courseId}
                getCode={getCode}
                type={type}
                passPercent={getPassPercent()}
              />
            )}
            {showDialogs() && assessmentData && openAssessment && (
              <AssessmentModal
                open={openAssessment}
                handleClose={handleCloseAssessment}
                questions={assessmentData.questions}
                id={course.me}
                assessmentId={assessmentData.assessment_id}
                courseName={course.foldersByParentId}
                courseId={courseId}
                type={type}
                handleSubmission={handleAssessmentSubmission}
                wmWeek={
                  course.validateAssessment && course.validateAssessment.wm_week
                }
                scoreArr={scoreArr}
                isResumeAssmt={postAssessResume || isPaused}
                code={code}
                rosterId={course.validateAssessment.roster_id}
                start={course?.validateAssessment?.start ?? ""}
                end={course?.validateAssessment?.end ?? ""}
                selectedLanguage={selectedLanguage}
                selectedLanguagePre={selectedLanguagePre}
              />
            )}
            {openResumeDialog && (
              <ResumeAssmtDialogBox
                openDialog={openResumeDialog}
                closeDialog={handleResumeDialog}
                assmtLanguage={selectedLanguage}
              />
            )}
            {openResumeDialogPre && (
              <ResumeAssmtDialogBox
                id="resume-dialog-pre"
                openDialog={openResumeDialogPre}
                closeDialog={handleResumeDialogPre}
                assmtLanguage={selectedLanguagePre}
              />
            )}
            {isMultiLanguagePost && (
              <Menu
                id="assessment-language-menu"
                anchorEl={anchorEl}
                open={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                keepMounted
                onClose={handleCloseLanguageSelectionMenu}
              >
                <MenuItem
                  id="assessment-language-menu-item"
                  onClick={() => {
                    setSelectedLanguage(
                      commonConstants.ASSESSMENT_LANGUAGE_ENGLISH
                    );
                    handleCloseLanguageSelectionMenu();
                    handleClickTake(
                      commonConstants.ASSESSMENT_LANGUAGE_ENGLISH
                    );
                  }}
                >
                  {commonConstants.ASSESSMENT_LANGUAGE_ENGLISH}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedLanguage(
                      commonConstants.ASSESSMENT_LANGUAGE_SPANISH
                    );
                    handleCloseLanguageSelectionMenu();
                    handleClickTake(
                      commonConstants.ASSESSMENT_LANGUAGE_SPANISH
                    );
                  }}
                >
                  {commonConstants.ASSESSMENT_LANGUAGE_SPANISH}
                </MenuItem>
              </Menu>
            )}

            {isMultiLanguagePre && (
              <Menu
                id="assessment-language-menu-pre"
                anchorEl={anchorElPre}
                open={anchorElPre}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                keepMounted
                onClose={handleCloseLanguageSelectionMenuPre}
              >
                <MenuItem
                  id="assessment-language-menu-item-pre"
                  onClick={() => {
                    setSelectedLanguage(
                      commonConstants.ASSESSMENT_LANGUAGE_ENGLISH
                    );
                    handleCloseLanguageSelectionMenuPre();
                    handleClickPre(commonConstants.ASSESSMENT_LANGUAGE_ENGLISH);
                  }}
                >
                  {commonConstants.ASSESSMENT_LANGUAGE_ENGLISH}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedLanguage(
                      commonConstants.ASSESSMENT_LANGUAGE_SPANISH
                    );
                    handleCloseLanguageSelectionMenuPre();
                    handleClickPre(commonConstants.ASSESSMENT_LANGUAGE_SPANISH);
                  }}
                >
                  {commonConstants.ASSESSMENT_LANGUAGE_SPANISH}
                </MenuItem>
              </Menu>
            )}
          </div>
        )}
      {!demoMode && !loading && error && (
        <SnackbarComponent
          open={errMsg}
          label={errMsg}
          isToken={false}
          okBtn={setErrMsg}
          isLoad={false}
        />
      )}
    </>
  );
};

export default AssessmentControls;
