import { callService, callServiceWithVar, callServiceWithTimeout } from '../../utils/services/graphql';
import {
    ASSESSMENT_DATA, CHECK_ASSESSMENT_CODE, SUBMIT_ASSESSMENT_MUTATION, ASSESSMENT_VALIDATION,
    ASSESSMENT_ANSWERS, ASSESSMENT_MUTATION_WITH_VAR, VALIDATE_ASSMT_TOKEN, VALIDATE_ASSESSMENT,
    ASSESSMENT_DOWNLOAD, UPDATE_COURSE_PROGRESS_MUTATION
} from '../../constants/QueryConstants';
import { UPSERT_ASSMT_TOKEN, RESET_PREV_ASSESSMENT } from '../../constants/MutationConstants';

const courseIdReplace = '<<courseId>>';
const courseRosterReplace = '<<courseRoster>>';
const assessmentIdReplace = '<<assessmentId>>';
const tokenIdReplace = '<<tokenId>>';

export const getAssessmentDataByCourseIdType = async (courseId, type, selectedLanguage, isAssessmentResume=false) => {
    let query = ASSESSMENT_DATA;
    query = query.replace(courseIdReplace, courseId);
    query = query.replace("<<type>>", type);
    query = query.replace("<<isAssessmentResume>>", isAssessmentResume)
    if(selectedLanguage){
        query = query.replace("<<language>>", selectedLanguage);
    } else {
        query = query.replace(', language: "<<language>>"', '')
    }
    return await callService(query);
};

export const validateAssessmentCode = async (courseId, assessmentCode, courseRoster) => {
    let query = CHECK_ASSESSMENT_CODE;
    query = query.replace(courseIdReplace, courseId);
    query = query.replace("<<assessmentCode>>", assessmentCode);
    query = query.replace(courseRosterReplace, courseRoster);
    return await callService(query);
};

export const submitAssessment = async (p, isAttendace) => {
    let query = SUBMIT_ASSESSMENT_MUTATION;
    if (isAttendace) {
        query = query.replace("<<questions>>", '[]');
    }
    else {
        let ques = JSON.stringify(p.questions);
        ques = ques.replace(new RegExp('"<<answer>>"', 'g'), "answer");
        ques = ques.replace(new RegExp('"<<id>>"', 'g'), "id");
        query = query.replace("<<questions>>", ques);
    }

    query = query.replace(courseIdReplace, p.courseId);
    query = query.replace(assessmentIdReplace, p.assessmentId);
    query = query.replace("<<academyId>>", p.academyId);
    query = query.replace("<<type>>", p.type);
    query = query.replace(courseRosterReplace, p.courseRoster);
    query = query.replace(tokenIdReplace, p.assmtToken);
    return await callService(query);
};

export const getAssessmentAnswers = async (assessmentId) => {
    let query = ASSESSMENT_ANSWERS;
    query = query.replace(assessmentIdReplace, assessmentId);
    return await callService(query);
};

export const getAssessmentAnswersWithTimeout = async (assessmentId, questionIds, tokenId, includeDeleted = false) => {
    let query = ASSESSMENT_VALIDATION;
    query = query.replace(new RegExp(assessmentIdReplace, 'g'), assessmentId);
    query = query.replace("<<questionIds>>", questionIds.toString());
    query = query.replace(tokenIdReplace, tokenId);
    query = query.replace("<<includeDeleted>>", includeDeleted)
    return await callServiceWithTimeout(query);
};

export const submitAssessmentAnswer = async (p) => {
    const query = ASSESSMENT_MUTATION_WITH_VAR;
    const variables = {
        assessmentId: p.assessmentId,
        question: p.question,
        answer: `ans-index-${p.answerIndex}`,
        updateReason: p.updateReason,
        tokenId: p.assmtToken
    }
    return await callServiceWithVar(query, 'asmtAnsUpdate', variables);
};

export const upsertAssmtToken = async (assessmentId, tokenId) => {
    let query = UPSERT_ASSMT_TOKEN;
    query = query.replace(assessmentIdReplace, assessmentId);
    query = query.replace(tokenIdReplace, tokenId);
    query = query.replace("<<group>>", "Assessment");
    return await callService(query);
};

export const validateAssmtToken = async (win, assessmentId, tokenId) => {
    let query = VALIDATE_ASSMT_TOKEN;
    query = query.replace("<<win>>", win);
    query = query.replace(assessmentIdReplace, assessmentId);
    query = query.replace(tokenIdReplace, tokenId);
    return await callService(query);
};

export const validateAssmt = async (courseId, courseRoster) => {
    let query = VALIDATE_ASSESSMENT;
    query = query.replace(courseIdReplace, courseId);
    query = query.replace(courseRosterReplace, courseRoster);
    return await callService(query);
};

export const downloadAssessment = async (courseId, courseName, assessmentId, type, withAnswerKeys = false, withOptionPercentage = false) => {
    return await callServiceWithVar(ASSESSMENT_DOWNLOAD, "assessmentDownload", Object.freeze({ courseId, courseName, assessmentId, type, withAnswerKeys, withOptionPercentage }));
}


export const updateCourseProgress = async (params) => {
    if (params.start <= 0 || params.end <= 0) {
        throw new Error('Start time and End time are mandatory and greater than 0')
    }
    let query = UPDATE_COURSE_PROGRESS_MUTATION;
    query = query.replace("<<winNum>>", params.userWin);
    query = query.replace(courseIdReplace, params.courseId);
    query = query.replace("<<source>>", "edu");
    query = query.replace("<<courseStatus>>", params.courseStatus);
    query = query.replace("<<startdate>>", params.start);
    query = query.replace("<<duedate>>", params.end);
    return callService(query);
}

export const resetPrevAssessment = async (assessmentId) => {
    let query = RESET_PREV_ASSESSMENT;
    query = query.replace(assessmentIdReplace, assessmentId);
    return await callService(query);
};
